exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-events-news-jsx": () => import("./../../../src/pages/blog-events-news.jsx" /* webpackChunkName: "component---src-pages-blog-events-news-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-capability-js": () => import("./../../../src/pages/capability.js" /* webpackChunkName: "component---src-pages-capability-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookies-policy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-footprint-js": () => import("./../../../src/pages/footprint.js" /* webpackChunkName: "component---src-pages-footprint-js" */),
  "component---src-pages-group-js": () => import("./../../../src/pages/group.js" /* webpackChunkName: "component---src-pages-group-js" */),
  "component---src-pages-group-leadership-jsx": () => import("./../../../src/pages/group/leadership.jsx" /* webpackChunkName: "component---src-pages-group-leadership-jsx" */),
  "component---src-pages-group-legacy-jsx": () => import("./../../../src/pages/group/legacy.jsx" /* webpackChunkName: "component---src-pages-group-legacy-jsx" */),
  "component---src-pages-group-our-brands-jsx": () => import("./../../../src/pages/group/our-brands.jsx" /* webpackChunkName: "component---src-pages-group-our-brands-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-jsx": () => import("./../../../src/pages/leadership.jsx" /* webpackChunkName: "component---src-pages-leadership-jsx" */),
  "component---src-pages-legacy-jsx": () => import("./../../../src/pages/legacy.jsx" /* webpackChunkName: "component---src-pages-legacy-jsx" */),
  "component---src-pages-milestones-js": () => import("./../../../src/pages/milestones.js" /* webpackChunkName: "component---src-pages-milestones-js" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-our-brands-jsx": () => import("./../../../src/pages/our-brands.jsx" /* webpackChunkName: "component---src-pages-our-brands-jsx" */),
  "component---src-pages-pages-template-blogs-template-jsx": () => import("./../../../src/pages/pages-template/BlogsTemplate.jsx" /* webpackChunkName: "component---src-pages-pages-template-blogs-template-jsx" */),
  "component---src-pages-pages-template-events-template-jsx": () => import("./../../../src/pages/pages-template/EventsTemplate.jsx" /* webpackChunkName: "component---src-pages-pages-template-events-template-jsx" */),
  "component---src-pages-pages-template-news-template-jsx": () => import("./../../../src/pages/pages-template/NewsTemplate.jsx" /* webpackChunkName: "component---src-pages-pages-template-news-template-jsx" */),
  "component---src-pages-post-page-js": () => import("./../../../src/pages/postPage.js" /* webpackChunkName: "component---src-pages-post-page-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-test-new-jsx": () => import("./../../../src/pages/test-new.jsx" /* webpackChunkName: "component---src-pages-test-new-jsx" */),
  "component---src-pages-test-new-page-jsx": () => import("./../../../src/pages/test-new-page.jsx" /* webpackChunkName: "component---src-pages-test-new-page-jsx" */),
  "component---src-pages-test-page-js": () => import("./../../../src/pages/testPage.js" /* webpackChunkName: "component---src-pages-test-page-js" */),
  "component---src-pages-trust-fund-js": () => import("./../../../src/pages/trustFund.js" /* webpackChunkName: "component---src-pages-trust-fund-js" */)
}

